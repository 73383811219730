import { useEffect, useRef, useState } from 'react'
import navLogo from '../../assets/images/nav-logo.svg'
import { Navbar, Nav } from 'react-bootstrap';
import { isUserToken } from '../../utils';
import { Link, useHistory } from 'react-router-dom';
import { USER_TOKEN_KEY, frontendRoutes } from '../../Constants/constants';
import { Button } from '../Button/Button';
import { GET_BUILDER_ID_SUCCESS, GET_PROPERTY_SUCCESS, SEARCH_COMMUNITY_SUCCESS } from '../../redux/types/actionTypes';
import { useDispatch } from 'react-redux';
import locationIcon from '../../assets/images/icn-location.svg'

type MenuList = {
    title: string,
    url: string,
    isAuthenticated: boolean,
    icon: string,
    subMenu: subMenuType[],
    isExternalLink: boolean,
}

type subMenuType = {
    title: string,
    url: string
}

interface MenuIconProps {
    isOpen: boolean,
    closeMenu: () => void
}

const MenuIcon = ({ isOpen, closeMenu }: MenuIconProps) => {
    return (
        <div className='mobile-menu-icon' onClick={() => closeMenu()}>
            <div className={`${isOpen ? 'close-menu bg-dark' : ''} menu-bar `}></div>
            <div style={{ opacity: isOpen ? 0 : 1 }} className={`menu-bar`}></div>
            <div className={`${isOpen ? 'close-menu-reverse bg-dark' : ''} menu-bar `}></div>
        </div>
    )
}

const MobileMenu = ({ builderImage }: any) => {
    const builder_name: string = localStorage.getItem('builder_name') || 'static_builder';
    const history = useHistory();
    const dispatch = useDispatch()
    
    const menuRef = useRef<HTMLDivElement | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenSubMenu, setIsOpenSubMenu] = useState<any>(null);

    const handleClick = ( {url, subMenu}: MenuList, index: number) => {

        if (url) {
            setIsOpen(false);
            history.push(url);
        } else if (subMenu.length > 0) {
            isOpenSubMenu === index ? setIsOpenSubMenu(null) : setIsOpenSubMenu(index)
        }
    }
    
      useEffect(() => {
        const closeMenu = (e: MouseEvent) => {
            e.stopPropagation()
            if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
                setIsOpenSubMenu(null);
            }
          };

        document.addEventListener('mouseup', closeMenu);
    
        return () => {
          document.removeEventListener('mouseup', closeMenu);
        };
      }, []);

      const onClickSubMenu = ( url: string, title: string ) => {
        if (title.toLocaleLowerCase() === 'sign out') {
            logout(url)
        } else {
            setIsOpenSubMenu(null)
            setIsOpen(false);
            history.push(url)
        }
      }

      const logout = (url: string) => {
        setIsOpenSubMenu(null)
        setIsOpen(false);
        dispatch({
            type: SEARCH_COMMUNITY_SUCCESS,
            payload: null,
          });
        dispatch({
            type: GET_PROPERTY_SUCCESS,
            payload: null,
          });
        dispatch({
            type: GET_BUILDER_ID_SUCCESS,
            payload: null,
          });
        localStorage.removeItem(USER_TOKEN_KEY);
        history.push(url)
      }

    const menuList: MenuList[] = [
        // {
        //     title: 'Map',
        //     url: `/${builder_name}/map`,
        //     isAuthenticated: false,
        //     icon: '',
        //     subMenu: [],
        //     isExternalLink: false
        // },
        {
            title: 'How it works',
            url: `/${builder_name}`,
            isAuthenticated: false,
            icon: '',
            subMenu: [],
            isExternalLink: false
        },
        {
            title: 'Help',
            url: 'https://nternow.freshdesk.com/support/solutions/articles/63000259067-self-tour-faq-',
            isAuthenticated: false,
            icon: '',
            subMenu: [],
            isExternalLink: true
        },
        {
            title: 'Book a Tour',
            url: `/${builder_name}`,
            isAuthenticated: true,
            icon: locationIcon,
            subMenu: [],
            isExternalLink: false
        },
        // {
        //     title: 'Map',
        //     url: frontendRoutes.propertyMap(builder_name).path,
        //     isAuthenticated: true,
        //     icon: locationIcon,
        //     subMenu: [],
        //     isExternalLink: false
        // },
        // {
        //     title: 'Map',
        //     url: `/${builder_name}/map`,
        //     isAuthenticated: true,
        //     icon: '',
        //     subMenu: [],
        //     isExternalLink: false
        // },
        {
            title: 'Tours',
            url: '',
            isAuthenticated: true,
            icon: '',
            subMenu: [
                {
                    title: 'Scheduled Tours',
                    url: frontendRoutes.upcomingTours(builder_name).path,
                },
                {
                    title: 'Completed Tours',
                    url: frontendRoutes.previousTours(builder_name).path,
                },
            ],
            isExternalLink: false
        },
        {
            title: 'Support',
            url: 'tel:+1-844-531-1283',
            isAuthenticated: true,
            icon: '',
            subMenu: [],
            isExternalLink: true
        },
        {
            title: 'My Profile',
            url: '',
            isAuthenticated: true,
            icon: '',
            subMenu: [
                {
                    title: 'Edit Profile',
                    url: frontendRoutes.editProfile(builder_name).path,
                },
                {
                    title: 'Sign Out',
                    url: `/${builder_name}`,
                },
            ],
            isExternalLink: false
        },
    ]

    return (
        <div className={`mobile-menu-container ${isOpen && 'mobile-menu-fixed'}`}>
            <MenuIcon isOpen={isOpen} closeMenu={() => setIsOpen(prev => !prev) } />
            <Link to={`/${builder_name}`}>
                <Navbar.Brand >
                    <img src={builderImage || navLogo} alt="icon" className='logo' />
                </Navbar.Brand>
            </Link>

            {/* Mobile Menu */}
            <section className={`mobile-side-menu ${isOpen ? '' : 'close-sidebar'}`}>
                <Nav className='top-nav' ref={menuRef}>
                    {menuList.filter(menu => isUserToken() ? menu.isAuthenticated : !menu.isAuthenticated).map((menu, index) => 
                        <div className='nav-item' key={index} >
                            {menu.isExternalLink ?
                                <a href={menu.url} target="_blank" className='nav-link' rel="noreferrer" >{menu.title}</a>    
                                :
                                <div className='nav-link' onClick={() => handleClick(menu, index)}>
                                    {menu.icon && <img src={menu.icon} alt="" style={{ width: '16px', marginRight: '1px',  }} />}
                                    {menu.title}
                                    {menu.subMenu.length > 0 && 
                                    <div className={`arrow-icon ${isOpenSubMenu === index ? 'arrow-up-animate' : ''}`} >&#8963;</div> }
                                </div>
                            }

                            {(isOpenSubMenu === index && menu.subMenu.length > 0) &&
                                <Nav className='sub-nav'>
                                    {menu?.subMenu?.map((nav, index) => 
                                         <p key={index} onClick={(e) => {
                                            e.stopPropagation()
                                            onClickSubMenu(nav.url, nav.title)
                                        }} >{nav.title}</p>
                                    )}
                                
                                </Nav>
                             }
                        </div>
                    )}
                    {!isUserToken() && 
                    <div className='nav-item'>
                        <Button 
                        type='button' 
                        onClick={() => history.push(frontendRoutes.signIn(builder_name).path)} 
                        className='signIn-btn button w-auto px-4'
                        style={{ boxShadow: '2px 6px 20px 2px rgba(0, 0, 0, 0.32)' }}
                        >
                            Sign In
                        </Button>

                        <div 
                        className='signIn-text nav-link py-3 px-4' 
                        onClick={() => (
                            history.push(frontendRoutes.signIn(builder_name).path,
                            setIsOpen(false)
                        ))}
                        >
                          Sign In
                        </div>
                    </div>
                    }
                </Nav>
                <Nav className='bottom-nav'>
                    <Nav.Link className='py-3 px-4'>Privacy Policy</Nav.Link> 
                    <Nav.Link className='py-3 px-4'>Terms & Legal</Nav.Link>
                    <Nav.Link className='py-3 px-4'>Housing Opportunities</Nav.Link>
                </Nav>
            </section>
        </div>
    )
}

export default MobileMenu