import { addDays, startOfDay, endOfDay, format } from 'date-fns';
import { isAndroid, isBrowser, isIOS, isMobile } from 'react-device-detect';
export const backendRoutes = {
  MOBILE_BASE_URL: process.env.REACT_APP_MOBILE_BASE_URL,
  MESSAGING_BASE_URL: process.env.REACT_APP_MESSAGING_BASE_URL,
  static_token: 'auth/token/static',
  message_token: 'auth/token/messaging',
  verify_phone_number: 'customers/verify/phone',
  validate_phone_number: 'customers/validate/phone',
  get_otp: 'texts/2fa',
  send_temp_password: 'customers/forgot-password',
  otp_verify: 'texts/verifycode',
  email_verify: 'customers/verify/email',
  auth_customer: 'auth/customers',
  customers: 'customers',
  offer: 'offer',
  // chooseCommunity: "properties/communities/10",
  checkEmail: 'customers/verify/email',
};

export const frontendRoutes = {
  signIn: (path: string = ':builder_name') => {
    return { path: `/${path}/sign-in`, name: '/sign-in' };
  },
  howItWorks: (path: string = ':builder_name') => {
    return { path: `/${path}/how-it-work`, name: '/how-it-work' };
  },
  phoneVerify: (path: string = ':builder_name') => {
    return { path: `/${path}/phone-verify`, name: '/phone-verify' };
  },
  otpVerify: (path: string = ':builder_name') => {
    return { path: `/${path}/otp-verify`, name: '/otp-verify' };
  },
  success: (path: string = ':builder_name') => {
    return { path: `/${path}/success`, name: '/success' };
  },
  isThisYou: (path: string = ':builder_name') => {
    return { path: `/${path}/is-this-you`, name: '/is-this-you' };
  },
  emailVerify: (path: string = ':builder_name') => {
    return { path: `/${path}/email-verify`, name: '/email-verify' };
  },
  textVerify: (path: string = ':builder_name') => {
    return { path: `/${path}/text-verify`, name: '/text-verify' };
  },
  documentVerify: (path: string = ':builder_name') => {
    return { path: `/${path}/doc-verify`, name: '/doc-verify' };
  },
  community: (path: string = ':builder_name', communityId: string = ':community_id') => {
    return { path: `/${path}/community/${communityId}`, name: '/community' };
  },
  chooseCommunity: (path: string = ':builder_name') => {
    return { path: `/${path}/choose-community`, name: '/choose-community' };
  },
  searchCommunity: (path: string = ':builder_name') => {
    return { path: `/${path}/search-community`, name: '/search-community' };
  },
  surveyQuestions: (path: string = ':builder_name') => {
    return { path: `/${path}/survey-questions`, name: '/survey-questions' };
  },
  previousTours: (path: string = ':builder_name') => {
    return { path: `/${path}/tours/previous`, name: '/tours/previous' };
  },
  upcomingTours: (path: string = ':builder_name') => {
    return { path: `/${path}/tours/upcoming`, name: '/tours/upcoming' };
  },
  preferTour: (path: string = ':builder_name') => {
    return { path: `/${path}/prefer-tour`, name: '/prefer-tour' };
  },
  cancelTour: (path: string = ':builder_name') => {
    return { path: `/${path}/cancel-tour`, name: '/cancer-tour' };
  },
  confirmYourself: (path: string = ':builder_name') => {
    return { path: `/${path}/confirm-yourself`, name: '/confirm-yourself' };
  },
  selectTourDate: (path: string = ':builder_name', propertyId = ':property_id') => {
    return { path: `/${path}/property/${propertyId}`, name: '/select-tour-date' };
  },
  shareLocation: (path: string = ':builder_name') => {
    return { path: `/${path}/share-location`, name: '/share-location' };
  },
  LockCodeScreen: (path: string = ':builder_name') => {
    return { path: `/${path}/lock-code-success`, name: '/lock-code-success' };
  },
  ConfirmTourScreen: (path: string = ':builder_name') => {
    return { path: `/${path}/confirm-tour-success`, name: '/confirm-tour-success' };
  },
  editProfile: (path: string = ':builder_name') => {
    return { path: `/${path}/account/edit-profile`, name: '/account/edit-profile' };
  },
  chooseTour: (path: string = ':builder_name') => {
    return { path: `/${path}/choose-tour`, name: '/choose-tour' };
  },
  enterProperty: (path: string = ':builder_name') => {
    return { path: `/${path}/enter-property`, name: '/enter-property' };
  },
  feedback: (path: string = ':builder_name') => {
    return { path: `/${path}/feedback`, name: '/feedback' };
  },
  offer: (path: string = ':builder_name') => {
    return { path: `/${path}/offer`, name: '/offer' };
  },
  propertyMap: (path: string = ':builder_name') => {
    return { path: `/${path}/map`, name: '/map' };
  },
  // tours: () => {
  //   return { path: `/tours/choose-tour`, name: '/tours' };
  // }
};

export const options = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
];

export const apiErrorStatus = {
  forbidden: -2,
  success: 1,
  otherError: -1,
  notFound: 0,
};

export const errorMessage = {
  signMessage: 'Your phone number is required.Please re-enter it or sign up for an account.',
};

// export const noBuilderTour = {
//   TOURS: 'tours'
// }

export const defineds = {
  startOfToday: startOfDay(new Date()),
  startOfLastTwoDay: startOfDay(addDays(new Date(), 2)),
  endOfToday: endOfDay(new Date()),
  startOfTomorrow: startOfDay(addDays(new Date(), 1)),
  endOfTomorrow: endOfDay(addDays(new Date(), 1)),
  endOfLastTwoDays: endOfDay(addDays(new Date(), 2)),
};

export const weekDays = [
  startOfDay(new Date()),
  startOfDay(addDays(new Date(), 1)),
  startOfDay(addDays(new Date(), 2)),
  startOfDay(addDays(new Date(), 3)),
  startOfDay(addDays(new Date(), 4)),
  startOfDay(addDays(new Date(), 5)),
  startOfDay(addDays(new Date(), 6)),
];

export const USER_TOKEN_KEY: string = 'user_token';
export const MESSAGING_TOKEN_KEY: string = 'message_token';

export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const currentDate = new Date().toLocaleDateString('en-GU', {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
});

export const now = format(new Date(), 'MMMM dd, yyyy HH:mm');

export const dateLabels = {
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
};

export const checkOS = () => {
  if (isMobile) {
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.nternow.android';
    } else if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/nternow-app/id1187749768';
    } else {
      window.open('https://nternow.com/self-touring-landing-page/', '_blank');
    }
  } else if (isBrowser) {
    window.open('https://nternow.com/self-touring-landing-page/', '_blank');
  }
};

export const defaultTourHours = [
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
];
