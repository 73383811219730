/* eslint-disable import/no-anonymous-default-export */
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from './reducer';

export type AppState = ReturnType<typeof rootReducer>;

const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["property"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer, // Use the persisted reducer
    composeWithDevTools(applyMiddleware(...middleware))
  );
  let persistor = persistStore(store); // Enable persistor
  return { store, persistor };
};
