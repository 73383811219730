import axios from 'axios';
import ReactImageGallery from 'react-image-gallery';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { format, isAfter, isBefore } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import {
  backendRoutes,
  convertTime12to24,
  currentDate,
  defaultTourHours,
  defineds,
  now,
  weekDays,
} from '../../../Constants/constants';
import { frontendRoutes } from '../../../Constants/constants';
import { tourDateValidation } from '../../../Constants/validations';
import {
  getAvailableTourHours,
  getBuilderId,
  getPropertyAdditionalInfo,
  get_property_details,
  scheduledVisits,
  tourDate,
  addStaticToken,
} from '../../../redux/action/action';
import Footer from '../../Footer/Footer';
import Loader from '../../Loader/Loader';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import defaultLogo from '../../../assets/images/default-logo.svg';
import { ReactComponent as FlashIcon } from '../../../assets/images/icn-flash.svg';
import locationIcon from '../../../assets/images/icn-location.svg';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { checkPropertyBuilder } from '../../../Constants/api';
import { Button } from '../../Button/Button';
import imagePlaceholder from '../../../assets/images/community-image-1.jpg';
import { honeypot } from '@honeypot-run/core';
import { isUserToken } from '../../../utils';

const SelectTourDate = () => {
  const [dateState, setDateState] = useState(new Date());
  const [timeState, setTimeState] = useState(format(new Date(), 'h:m a'));
  const [label, setLabel] = useState('Today');
  const [scheduleLabel, setScheduleLabel] = useState("Tour Now")
  const [nextPage, setNextPage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [tourHours, setTourHours] = useState<string[] | null>(null);
  const [tourHoursStart, setTourHoursStart] = useState<string>('');
  const [tourHoursEnd, setTourHoursEnd] = useState<string>('');
  const [secondWindowTourHoursStart, setSecondWindowTourHoursStart] = useState<string>('');
  const [secondWindowTourHoursEnd, setSecondWindowTourHoursEnd] = useState<string>('');
  const [areNoSlotAvailable, setAreNoSlotAvailable] = useState(false);
  const [isUnavailableDay, setIsUnavailableDay] = useState(false);
  const [didSelectSlot, setDidSelectSlot] = useState(false);
  const [isPropertyBuilder, setIsPropertyBuilder] = useState(false);
  const [propertyGallery, setPropertyGallery] = useState<any[]>([]);

  const scrollContainerRef = useRef<HTMLUListElement | null>(null);
  const params = useParams<{ builder_name?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const builderSettings = useSelector(selectBuilderSettings);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const propertyId = useSelector((state: any) => state?.property?.property?.id);
  const propertyAddress = useSelector((state: any) => state?.property?.property?.address);
  const communityName = useSelector((state: any) => state?.property?.property?.communityName);
  const propertyBuilder = useSelector((state: any) => state?.property?.property?.builder);
  const propertyImages = useSelector((state: any) => state?.property?.property?.images);
  const isScheduledVisitLoading = useSelector((state: any) => state?.scheduledVisit?.loading);
  const error = useSelector((state: any) => state?.scheduledVisit?.error);
  const visit = useSelector((state: any) => state?.scheduledVisit?.scheduledVisits);
  const availableTourHours = useSelector((state: any) => state?.property?.availableTourHours);
  const isLoading = useSelector((state: any) => state?.property?.loading);
  const attribution = useSelector((state: any) => state.builder.attribution);
  const isRental = useSelector((state: any) => state.builder.isRental);
  const propertyLotNumber = useSelector((state: any) => state?.property?.property?.lotNumber);
  const propertyPrice = useSelector((state: any) => state?.property?.property?.price);
  const propertyBeds = useSelector((state: any) => state?.property?.property?.beds);
  const propertyBaths = useSelector((state: any) => state?.property?.property?.baths);
  const propertyHBaths = useSelector((state: any) => state?.property?.property?.hBaths);
  const propertySqft = useSelector((state: any) => state?.property?.property?.sqft);

  var isOutsideTourHours = true;

  if (isAfter(new Date(), new Date(`${currentDate} ${tourHoursStart}`)) && isBefore(new Date(), new Date(`${currentDate} ${tourHoursEnd}`))) {
    isOutsideTourHours = false
  }

  if (isAfter(new Date(), new Date(`${currentDate} ${secondWindowTourHoursStart}`)) && isBefore(new Date(), new Date(`${currentDate} ${secondWindowTourHoursEnd}`))) {
    isOutsideTourHours = false
  }

  const token = localStorage.getItem('static_token');

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [builderId, dispatch]);

  // useEffect(() => {
  //   if (!tourHours) {
  //     return;
  //   }

  //   const start = tourHours[0];
  //   const end = tourHours[tourHours.length - 1];

  //   setTourHoursStart(start);
  //   setTourHoursEnd(end);
  // }, [tourHours]);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      showDate: '',
      radioTime: 'now',
      customTime: '',
    },
    enableReinitialize: true,
    validationSchema: tourDateValidation,
    onSubmit: values => {
      const nowDate = 'Today ' + currentDate;
      if (
        values.radioTime === 'now' &&
        (values.showDate === currentDate || values.showDate === nowDate)
      ) {
        const data = {
          showDate: values.showDate === currentDate ? nowDate : values.showDate,
          radioTime: values.radioTime,
          customTime: new Date().toLocaleTimeString('en-GU', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        };

        dispatch(tourDate(data));

        honeypot.track(
					'Tour Now - Code Requested',
					{ propertyId: propertyId });

        const isDemoMode = propertyBuilder.toLowerCase() === 'demo mode';

        isDemoMode
          ? history.push(frontendRoutes.LockCodeScreen(params.builder_name).path)
          : history.push(frontendRoutes.shareLocation(params.builder_name).path);
      } else {
        const time =
          values.radioTime === 'custom'
            ? values.customTime
            : new Date().toLocaleTimeString('en-GU', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });

        const t = convertTime12to24(time);
        const n = new Date(now);
        const dt = new Date(values.showDate + ' ' + t);
        const nowDate = 'Today ' + currentDate;
        const tomorrow = defineds.startOfTomorrow.toLocaleDateString('en-GU', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        });
        const tDate = 'Tomorrow ' + tomorrow;

        if (dt.getTime() > n.getTime()) {
          const visitDate = {
            propertyId,
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
            hour: dt.getHours(),
            minute: dt.getMinutes(),
          };

          const data = {
            showDate:
              values.showDate === currentDate
                ? nowDate
                : values.showDate === tomorrow
                ? tDate
                : values.showDate,
            radioTime: values.radioTime,
            customTime: values.radioTime === 'custom' ? values.customTime : time,
          };
          dispatch(tourDate(data));
          dispatch(scheduledVisits(visitDate, attribution));
          honeypot.track(
            'Scheduled Tour',
            { propertyId: propertyId, visitDate });
          setNextPage(true);
        } else {
          if (values.customTime === '') {
            const msg = 'Required';
            setShowAlert(true);
            setMessage(msg);
            setTimeout(() => {
              setShowAlert(false);
              setMessage('');
            }, 2000);
          } else {
            const msg = 'Time must be greater than current time';
            setShowAlert(true);
            setMessage(msg);
            setTimeout(() => {
              setShowAlert(false);
              setMessage('');
            }, 2000);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!availableTourHours?.isToursAvailable) {
      setTourHours(defaultTourHours);
      setIsUnavailableDay(true);
      return;
    }

    setTourHours(availableTourHours?.availableSlots);
    setTourHoursStart(availableTourHours.tourStartSlot);
    setTourHoursEnd(availableTourHours.tourEndSlot);
    setSecondWindowTourHoursStart(availableTourHours.secondWindowTourStartSlot);
    setSecondWindowTourHoursEnd(availableTourHours.secondWindowTourEndSlot);
    setIsUnavailableDay(false);
    if (!isOutsideTourHours) {
      setDidSelectSlot(label === "Today" && format(new Date(), 'h:m a') === timeState );
    } else {
      setDidSelectSlot(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTourHours]);

  useEffect(() => {
    const isAnyAvailable = tourHours?.reduce((exists, time) => {
      if (exists) {
        return true;
      }
      const nowTime = new Date(now).getTime();
      const dayTime = new Date(currentDate + ' ' + time).getTime();
      const disabled = dayTime < nowTime && label === 'Today';

      return !disabled;
    }, false)
    setAreNoSlotAvailable(!isAnyAvailable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourHours]);

  useEffect(() => {
    if (error && isScheduledVisitLoading) {
      return;
    }

    setShowAlert(true);
    setMessage(error);

    setTimeout(() => {
      setShowAlert(false);
      setMessage('');
    }, 5000);
  }, [error, isScheduledVisitLoading]);

  useEffect(() => {
    if (visit && !error && nextPage && !isScheduledVisitLoading) {
      history.push(frontendRoutes.ConfirmTourScreen(params.builder_name).path);
    }
  }, [error, history, isScheduledVisitLoading, nextPage, params.builder_name, visit]);

  useEffect(() => {
    if (isPropertyBuilder) {
      dispatch(
        getAvailableTourHours(propertyId, {
          year: dateState.getFullYear(),
          month: dateState.getMonth() + 1,
          day: dateState.getDate(),
        }),
      );
    }
  }, [dateState, dispatch, isPropertyBuilder, propertyId]);

  useEffect(() => {
    const startDate = dateState.toLocaleDateString('en-GU', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

    const finalDate = label !== ' ' ? label + ' ' + startDate : startDate;
    formik.setFieldValue('showDate', finalDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState, label]);

  const previousPath = () => {
    const historyLocationState = history.location.state as { from?: string };

    if (historyLocationState?.from === 'PropertyMap') {
      history.push(frontendRoutes.propertyMap(params.builder_name).path);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    }

    const { current: scrollContainer } = scrollContainerRef;

    const handleWheel = (e: WheelEvent) => {
      if (e.deltaX === -0) {
        e.preventDefault();

        scrollContainer.scrollBy({
          left: e.deltaY < 0 ? -30 : 30,
        });
      }
    };

    scrollContainer.addEventListener('wheel', handleWheel);

    return () => {
      scrollContainer.removeEventListener('wheel', handleWheel);
    };
  }, [scrollContainerRef]);

  useEffect(() => {
    if (!builderId) {
      return;
    }

    const checkBuilder = async () => {
      await dispatch(addStaticToken());
      const propertyBuilderId = await checkPropertyBuilder(propertyId);

      if (propertyBuilderId !== builderId) {
        history.push(`/${params.builder_name}`);
        return;
      }

      setIsPropertyBuilder(true);

      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `properties/${propertyId}/info`,
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      })
        .then(res => {
          const data = { id: propertyId, ...res.data };

          dispatch(get_property_details(data));
          dispatch(getPropertyAdditionalInfo(data.id));
        })
        .catch(err => {
          console.log(err);
        });
    };

    checkBuilder();
  }, [builderId, dispatch, history, params.builder_name, propertyId, token]);

  useEffect(() => {
    const images = propertyImages?.map((original: string) => {
      const imageUrl = propertyBuilder?.toLowerCase().includes('kbhome')
        ? original
        : `//images.weserv.nl?url=${encodeURIComponent(original)}&w=460&output=webp&il`;
      return {
        original: imageUrl,
        originalWidth: '460px',
        originalHeight: '460px',
      };
    });

    setPropertyGallery(images);
  }, [propertyBuilder, propertyImages]);

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <figure>
            <img src={builderSettings?.logo || defaultLogo} alt="Fairfield" />
          </figure>
          <span className="text-blue">
            <sup>*</sup>required
          </span>
        </div>
        {isUserToken() && <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>}
        {propertyGallery?.length > 0 && isUserToken() && (
          <ReactImageGallery
            items={propertyGallery}
            showPlayButton={false}
            showFullscreenButton={false}
            additionalClass="image-gallery-custom"
            onErrorImageURL={imagePlaceholder}
          />
        )}
        {!isUserToken() && <div className="box-data d-flex flex-column justify-content-between">
          <div className="tour-card">
            {propertyGallery?.length > 0 && (
              <ReactImageGallery
                items={propertyGallery}
                showPlayButton={false}
                showFullscreenButton={false}
                additionalClass="image-gallery-custom"
                onErrorImageURL={imagePlaceholder}
              />
            )}
            <div className="item item-hide-image p-3">
              <div
                className="item-data"
                onClick={() => history.push(frontendRoutes.signIn(params.builder_name).path)}
              >
                <span className="icn icn-back-arrow"></span>
                <h2 className="full-address">
                  {propertyLotNumber ? `Lot ${propertyLotNumber}, ${propertyAddress}` : propertyAddress}
                </h2>
                <small>Property ID - {propertyId}</small>
                {propertyPrice > 0 && <span className="small">${new Intl.NumberFormat('en-US').format(propertyPrice)}{isRental ? '/month' : ''}</span>}
                <ul className="d-flex small">
                  <li>
                    <i className="icn icn-bad"></i>
                    {propertyBeds}Bd
                  </li>
                  <li>
                    <i className="icn icn-bath"></i>
                    {propertyBaths}Fba
                  </li>
                  <li>
                    <i className="icn icn-bath"></i>
                    {propertyHBaths}Hba
                  </li>
                  <li>
                    <i className="icn icn-feet"></i>
                    {propertySqft}Sqft
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <Button
              type='button'
              onClick={() => history.push(frontendRoutes.signIn(params.builder_name).path)}
              className='button w-100 px-4'
              style={{ boxShadow: '2px 6px 20px 2px rgba(0, 0, 0, 0.32)' }}
            >
              Register to Tour
            </Button>
        </div>
        }
        {isUserToken() && <div className="box-data d-flex flex-column">
          <div className="tour-address d-flex align-items-start gap-2 mb-4">
            <img src={locationIcon} alt="location-icon" />
            <p>
              {propertyAddress} {communityName && `in ${communityName}`}
            </p>
          </div>
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column h-100">
            <FormikProvider value={formik}>
              <Form.Group controlId="" className="form-group form-date">
                <Form.Label>
                  What day would you like to tour?<sup>*</sup>
                </Form.Label>
                <ul ref={scrollContainerRef} className="date-list">
                  {weekDays.map(day => {
                    const today = day.getDate() === new Date().getDate();

                    return (
                      <li
                        key={day.getTime()}
                        className={`${
                          dateState.getDate() === day.getDate() ? 'date-item active' : 'date-item'
                        }`}
                        onClick={e => {
                          const target = e.target as HTMLLIElement;

                          switch (Number(day)) {
                            case Number(defineds.startOfToday):
                              setLabel('Today');
                              setTimeState('');
                              break;

                            case Number(defineds.startOfTomorrow):
                              setLabel('Tomorrow');
                              setTimeState('');
                              formik.setFieldValue('radioTime', '');
                              break;

                            default:
                              setLabel(' ');
                              setTimeState('');
                              formik.setFieldValue('radioTime', '');
                          }

                          setDateState(day);

                          target?.scrollIntoView({
                            behavior: 'smooth',
                            inline: 'nearest',
                            block: 'nearest',
                          });
                        }}
                      >
                        <p className="item-month">{format(day, 'MMM')}</p>
                        <p className="item-date">{day.getDate()}</p>
                        <p className="item-day">{today ? 'TODAY' : format(day, 'EE')}</p>
                      </li>
                    );
                  })}
                </ul>
              </Form.Group>
              <Form.Group
                controlId=""
                className={
                  formik.errors.radioTime
                    ? 'form-group form-custom mb-0 is-invalid'
                    : 'form-group form-custom mb-0'
                }
              >
                <Form.Label>
                  What time would you like to tour?<sup>*</sup>
                </Form.Label>
                <ul className="time-list">
                  {label === 'Today' && !isOutsideTourHours && !isUnavailableDay && !isLoading && (
                    <li
                      className={`${
                        format(new Date(), 'h:m a') === timeState && 'active'
                      } time-item`}
                      onClick={() => {
                        setTimeState(format(new Date(), 'h:m a'));
                        setDidSelectSlot(true);
                        setScheduleLabel("Tour Now")
                        formik.setFieldValue('radioTime', 'now');
                      }}
                    >
                      <button
                        type="button"
                        className={`${formik.values.radioTime === 'now' && 'active'} time-button`}
                      >
                        <FlashIcon />
                        NOW
                      </button>
                    </li>
                  )}
                  {!isLoading &&
                    tourHours?.map((time: string) => {
                      const active = time === timeState && formik.values.radioTime !== 'now';
                      const nowTime = new Date(now).getTime();
                      const dayTime = new Date(currentDate + ' ' + time).getTime();
                      const disabled = dayTime < nowTime && label === 'Today';

                      return (
                        <li
                          key={time}
                          className={`${active && 'active'} time-item`}
                          onClick={() => {
                            setTimeState(time);
                            setDidSelectSlot(true);
                            setScheduleLabel("Schedule")
                            formik.setFieldValue('customTime', time);
                            formik.setFieldValue('radioTime', 'custom');
                          }}
                        >
                          <button
                            type="button"
                            className={`${active && 'active'} time-button`}
                            disabled={disabled || isUnavailableDay}
                          >
                            {time}
                          </button>
                        </li>
                      );
                    })}
                </ul>
                <span className="invalid-feedback d-block">{showAlert ? message : ''}</span>
                <span className="invalid-feedback d-block">
                  {formik.errors.radioTime ? 'Required' : ''}
                  {formik.errors.customTime ? formik.errors.customTime : ''}
                </span>
                &nbsp;
                {areNoSlotAvailable && <Form.Label>
                  <b>There are no more tour slots available today. Please select another day.</b>
                </Form.Label>}
              </Form.Group>
              {isLoading && <LoadingSpinner />}
            </FormikProvider>
            {isScheduledVisitLoading && <Loader />}
            {!isLoading &&
              <div className="box-footer mt-auto">
              <Button
                type="submit"
                className={`button w-100 ${(isUnavailableDay || areNoSlotAvailable || !didSelectSlot || (isOutsideTourHours && !didSelectSlot)) ? 'disabled' : ''}`}
                disabled={isUnavailableDay || areNoSlotAvailable || !didSelectSlot || (isOutsideTourHours && !didSelectSlot) }
              >
                {scheduleLabel}
              </Button>
            </div>}
          </Form>
        </div>
        }
        <Footer />
      </div>
    </section>
  );
};

export default SelectTourDate;
