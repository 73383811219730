import { useEffect, useState } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { backendRoutes, errorMessage, frontendRoutes } from '../../../Constants/constants';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStaticToken,
  getBuilderId,
  getPhoneNumber,
  search_community,
} from '../../../redux/action/action';
import { phValidationSchema } from '../../../Constants/validations';
import { setTimeout } from 'timers';
import { setToLogin, isUserTokenExpired, changePhoneNumberFormat } from '../../../utils';
import InputMask from 'react-input-mask';
import { Button } from '../../Button/Button';
import { honeypot } from '@honeypot-run/core';

const SignInForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ builder_name?: string }>();

  const builderId = useSelector((state: any) => state.builder.builder_id);
  const propertyId = useSelector((state: any) => state?.property?.property?.id);
  const builderCommunities = useSelector((state: any) => state?.community?.community);

  const token = localStorage.getItem('static_token');
  const user_token = localStorage.getItem('user_token');

  useEffect(() => {
    if (!propertyId || !builderCommunities) {
      return;
    }

    const propertyCommunity = builderCommunities?.reduce(
      (
        acc: number | null,
        {
          community,
        }: {
          community: {
            community_id: number;
            properties: number[];
          };
        },
      ) => {
        const { community_id, properties } = community;

        return properties.includes(propertyId) ? (acc = community_id) : acc;
      },
      null,
    );

    if (propertyCommunity) {
      dispatch(
        search_community({
          choose: {
            community_id: propertyCommunity,
          },
        }),
      );
    }
  }, [builderCommunities, dispatch, propertyId]);

  useEffect(() => {
    if (!builderId) {
      dispatch(addStaticToken());
      dispatch(getBuilderId());
    }
  }, [builderId, dispatch]);

  const previousPath = () => {
    const historyLocationState = history.location.state as { from?: string };

    if (historyLocationState?.from === 'PropertyMap') {
      history.push(frontendRoutes.propertyMap(params.builder_name).path);
    } else {
      history.goBack();
    }
  };

  const phone_verify = (formData: any) => {
    setIsLoading(true);
    axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: backendRoutes.validate_phone_number,
      method: 'post',
      headers: {
        'x-auth-token': token,
      },
      data: formData,
    })
      .then(res => {
        if (res.status === 201) {
          setIsLoading(false);

          honeypot.track(
            'Sign In - Phone Number Verified',
            { phone: formData.phone });

          if (user_token && !isUserTokenExpired(user_token)) {
            history.push(frontendRoutes.preferTour(params.builder_name).path);
            return;
          }

          setToLogin();
          history.push(frontendRoutes.emailVerify(params.builder_name).path);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          setIsLoading(false);
          localStorage.removeItem('SET_TO_LOGIN');
          history.push(frontendRoutes.phoneVerify(params.builder_name).path)
          return;
        }

        if (formik.values.phoneNumber === '') {
          setIsLoading(false);
          setShowAlert(true);
          setMessage(errorMessage.signMessage);
          return;
        }

        setIsLoading(false);
        setShowAlert(true);
        setMessage('Please check your connection, Network Error !!!');
        setTimeout(() => {
          setShowAlert(false);
        }, 1000);
      });
  };
  const gotoRegister = () => {
    dispatch(getPhoneNumber(''));
    localStorage.removeItem('SET_TO_LOGIN');
    history.push(frontendRoutes.phoneVerify(params.builder_name).path);
  };
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: phValidationSchema,
    onSubmit: values => {
      const data = {
        phone: changePhoneNumberFormat(values.phoneNumber),
      };
      phone_verify(data);
      dispatch(getPhoneNumber(data));
    },
  });

  return (
    <section className="main-section signup-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <Form onSubmit={formik.handleSubmit} className="h-100 d-flex flex-column">
            <FormikProvider value={formik}>
              <Form.Group controlId="formBasicEmail">
                {showAlert === false ? (
                  <Form.Label>Sign in with your phone number.</Form.Label>
                ) : (
                  <Form.Label>{message}</Form.Label>
                )}
                <InputMask
                  mask="999-999-9999"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                >
                  {() => (
                    <Form.Control
                      type="text"
                      placeholder="000-000-0000"
                      name="phoneNumber"
                      isInvalid={!!formik.errors.phoneNumber}
                      className={showAlert ? 'is-invalid' : ''}
                    />
                  )}
                </InputMask>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </FormikProvider>
            <div className="devider">
              <span>OR</span>
            </div>
            <p className="mb-0">
              Need an account with NterNow?{' '}
              <span className="a" onClick={gotoRegister}>
                Register here
              </span>
              .
            </p>

            <div className="box-footer mt-auto">
              {/* <small>
                I understand that during the COVID-19 crisis, I am touring at my own risk and will
                ensure to follow the 5 CDC recommended guidelines when conducting a self-tour. For
                more information:{' '}
                <a href="https://nternow.com/" rel="noreferrer" target="_blank">
                  COVID-19 policy
                </a>
                .
              </small> */}
              {isLoading && <Loader />}
              <Button className="button w-100" type="submit">
                Continue
              </Button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
      <a href="https://nternow.com/" rel="noreferrer" className="help-link" target="_blank">
        Need Help?
      </a>
    </section>
  );
};

export default SignInForm;
