import React from 'react';
import { ChevronRight, Home, Calendar, Phone } from "lucide-react"
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { frontendRoutes } from '../../../../Constants/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const FeedbackSuccessModal = ({ open, setOpen }: Props) => {
  const params = useParams<{ builder_name?: string }>();
  const history = useHistory();

  const reservationUrl = useSelector((state: any) => state?.property?.property?.reservation_url);
  const community = useSelector((state: any) => state?.community?.community);

  const handleReserve = () => {
    if (reservationUrl) {
      window.open(reservationUrl, '_blank', );
      history.push(frontendRoutes.community(params.builder_name, community?.[0].community?.community_id).path);
    } else {
      history.push(frontendRoutes.offer(params.builder_name).path);
    }
  }
  const handleOtherProperties = () => history.push(frontendRoutes.propertyMap(params.builder_name).path);

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      centered
      className="feedback-modal"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="text-black modal-title">
          Thank you for Touring!
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-4">
          <h5 className="text-black fs-5">More options:</h5>
          <ListGroup>
            <ListGroupItem
              className="p-3 mt-2"
              onClick={handleOtherProperties}
            >
              <div className="d-flex justify-content-between align-items-center bo">
                <div className="d-flex align-items-center">
                  <div className="icon-container home">
                    <Home/>
                  </div>
                  <div className="ms-3">
                    <div className="fw-bold">Find Other Properties to Tour</div>
                    <div className="text-muted">Explore other self-tour homes near you</div>
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-muted" />
              </div>
            </ListGroupItem>
          </ListGroup>
        </div>

        <div className="mb-4">
          <h5 className="text-black fs-5">Ready to make a decision?</h5>
          <ListGroup>
            <ListGroupItem
              className="p-3 mt-2"
              onClick={handleReserve}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="icon-container calendar">
                    <Calendar/>
                  </div>
                  <div className="ms-3">
                    <div className="fw-bold">Reserve this home!</div>
                    <div className="text-muted">Start the process to secure this property</div>
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-muted" />
              </div>
            </ListGroupItem>
          </ListGroup>
        </div>

        <div className="mb-4">
          <h5 className="text-black fs-5">Need more information?</h5>
          <ListGroup>
            <a href="tel:+1-844-531-1283" className="text-black" target="_blank" rel="noreferrer">
              <ListGroupItem className="p-3 mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="icon-container phone">
                      <Phone/>
                    </div>
                    <div className="ms-3">
                      <div className="fw-bold">Call a Sales Professional</div>
                      <div className="text-muted d-flex flex-column">
                        <span>Speak directly with our team about all</span>
                        <span>of the incentives we are offering!</span>
                      </div>
                    </div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-muted" />
                </div>
              </ListGroupItem>
            </a>
          </ListGroup>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FeedbackSuccessModal;
